




































import Vue from 'vue'
import ExpertList from './expert_list.vue'
import ConsultingList from './consulting_list.vue'
export default Vue.extend({
  components: {
    ConsultingList,
    ExpertList,
  },
  computed: {
    areaList() {
      return this.$store.state.areaList
    },
  },
  data() {
    return {
      active: 1,
      name: '',
      areaCode: '',
      industryList: ['气象', '茶叶', '其他'],
      // industry: '1',
      industry: '',
    }
  },
  // watch: {
  //     active(val) {
  //         if (val == 0) {
  //             // if(!this.industry) this.industry = '1'
  //             // if(this.areaCode) this.areaCode = ''
  //         } else if(val == 1) {
  //             // if(this.industry) this.industry = ''
  //             // if(!this.areaCode && this.areaList && this.areaList.length > 0) this.areaCode = this.areaList[0].id
  //         }
  //     }
  // },
  created() {
    const activeIdxStr = this.$route.params.activeIdx
    const activeIdx = Number(activeIdxStr)
    this.active = activeIdx
    // if(!this.industry) this.industry = '1'
    // if(!this.areaCode && this.areaList && this.areaList.length > 0) this.areaCode = this.areaList[0].id
    // const activeIdxStr = this.$route.params.activeIdx // 城市参数
    // try {
    //     const activeIdx = Number(activeIdxStr)
    //     if(activeIdx<2) this.active = activeIdx
    // } catch (error) {
    // // do nothing.
    // }
  },
})
